<template>
  <div class="Activity__Component">
    <div class="Activity__Component__Tabs">
      <div class="Activity__Component__Tabs__Item" @click="loadQuestion()" :class="{ active: tab === 1 }">
        עריכה
      </div>
      <!-- <div class="Activity__Component__Tabs__Item" @click="loadQuestionItems()" :class="{ active: tab === 2 }">
        שאלות בתרגול
      </div> -->
    </div>
    <div class="Activity__Component__TabsContent">
      <!-- <new-activity ref="noteActivity" v-if="showNewNote" type="Note" @onClose="close" @onSave="saveNote" />
      <new-activity ref="taskActivity" v-if="showNewTask" type="Task" show-title="true" @onClose="closeTask"
        @onSave="saveTask" /> -->
      <template v-if="isLoading">
        <div class="loading">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
        </div>
      </template>
      <div v-if="tab === 1">
        <div class="Activity__Component__TabsContent__Tasks">
          <div v-if="question" class="Activity__Component__TabsContent__Tasks__Content">

            <div class="item">
              <h1 class="bold-text" style="display: flex">נתוני חכמת ההמונים</h1>
              <div class="question-details">
                <div class="question-detail">
                  <b-icon title="1- הכי קלה, 10-הכי קשה. נתון זה מתבסס על חכמת ההמונים" pack="fa"
                    icon="exclamation"></b-icon>
                  <p>רמת קושי: {{ question.difficultyLevel }} ({{ studentAnswers.difficultyPercentage }}% הצלחה)</p>
                </div>
                <div class="question-detail">
                  <b-icon title="כמות משיבים כללית לשאלה זו" pack="fa" icon="exclamation"></b-icon>
                  <p>כמות משיבים: {{ studentAnswers.studentAnswersCount }}</p>
                </div>
                <div class="question-detail">
                  <b-icon title="זמן ממוצע נטו למענה על שאלה זו" pack="fa" icon="exclamation"></b-icon>
                  <p v-if="studentAnswers.averageQuestionTime && question.averageQuestionTime">זמן ממוצע למענה: {{
                    parseTime(studentAnswers.averageQuestionTime) }}
                  </p>
                </div>
              </div>
              <div class="question-details">
                <div class="question-detail">
                  <b-icon pack="fa" icon="exclamation"></b-icon>
                  <p>אחוז סימוני כוכבית: {{ studentAnswers.starPercentage }}%</p>
                </div>
                <div class="question-detail">
                  <b-icon pack="fa" icon="exclamation"></b-icon>
                  <p>ציון כוכבית: {{ studentAnswers.starGrade || 'אין נתונים' }}</p>
                </div>
                <div class="question-detail">
                  <b-icon pack="fa" icon="exclamation"></b-icon>
                  <p>אחוז אפורות: {{ studentAnswers.grayPercentage }}</p>
                </div>
                <div class="question-detail">
                  <b-icon pack="fa" icon="exclamation"></b-icon>
                  <p>ציון אפורות: {{ studentAnswers.grayGrade }}</p>
                </div>
              </div>
            </div>

            <div class="item">
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 100%;" label="סטטוס:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="[{ Value: false, Text: 'לא פעיל' }, { Value: true, Text: 'פעיל' }]"
                    v-model="question.status" />
                </b-field>
                <b-field style="width: 100%;" label="מוסד:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAcademicInstitudes" v-model="question.academicInstitutionId" />
                </b-field>
                <b-field style="width: 100%;" label="מחבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAuthors" v-model="question.authorId" />
                </b-field>
                <div style="width: 100%;">
                  <b-field style="width: 100%;" label="נוצר על ידי:">
                    <div>{{ question.creatorUserName }} ({{ parseDate(question.createdDate) }})</div>
                  </b-field>
                  <a>היסטוריית עריכה</a>
                </div>
              </div>
            </div>

            <div class="item">
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 100%;" label="סוג שאלה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true"
                    :options="[{ Value: 1, Text: 'Default' }, { Value: 4, Text: 'טקסט' }, { Value: 5, Text: 'YesNo' }]"
                    v-model="question.exerciseQuestionTypeId" />
                </b-field>
                <b-field style="width: 100%;" label="שם שאלה:">
                  <input type="text" style="border: 1px solid gray; width: 100%; padding: 14px;" v-model="question.name"
                    name="" id="">
                </b-field>
                <b-field style="width: 100%;" label="תיאור קצר:">
                  <input type="text" style="border: 1px solid gray; width: 100%; padding: 14px;"
                    v-model="question.questionDescription" name="" id="">
                </b-field>
                <b-field style="width: 100%;" label="קטגוריות:">
                  <!-- <FieldInlineSelect :multiple="true" :hideHover="true" style="border: 1px solid gray; width: 100%;"
                    :filterable="true" :clearable="true" :optionsAsync="getCategories"
                    v-model="question.exerciseCategoryIds" /> -->
                  <v-select dir="rtl" label="Text" v-model="question.exerciseCategoryIds" :filterable="true"
                    :clearable="true" :reduce="item => item.Value" :getOptionLabel="(item) => `${item.Text}`"
                    :options="categories" :multiple="true" :searchable="true" :close-on-select="false"
                    :placeholder="'בחר ערך מהרשימה'"> </v-select>
                </b-field>
              </div>
            </div>

            <!-- <div class="item">
              <Tree id="my-tree-id" ref="my-tree-ref" :custom-options="myCustomOptions" :custom-styles="myCustomStyles"
                :nodes="treeCategories"></Tree>
            </div> -->

            <div class="item">
              <b-field label="שאלה">
                <template>
                  <!-- <ckeditor :editor="editor" v-model="question.question" :config="editorConfig"></ckeditor> -->
                  <NewActivityEditor @onEditorChange="setQuestion" v-if="ready" :ckEditorContent="question.question"
                    :content="question.question" ref="question" />
                </template>
              </b-field>
            </div>

            <div class="item">
              <b-field label="תשובה מפורטת">
                <template>
                  <!-- <ckeditor :editor="editor" v-model="question.detailedAnswer" :config="editorConfig"></ckeditor> -->
                  <NewActivityEditor @onEditorChange="setDetailed" v-if="ready" :ckEditorContent="question.detailedAnswer"
                    :content="question.detailedAnswer" ref="detailedAnswer" />
                </template>
              </b-field>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;">
                <b-field style="width: 50%" label="סוג אפקט בשאלה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="getEffectTypes" v-model="question.effectId" />
                </b-field>
                <b-checkbox class="bold-text" v-model="question.isHideAnswers">הסתרת חלופות כדיפולט?</b-checkbox>
              </div>
            </div>

            <div class="item">
              <b-field label="נושא שאלה לדוחות ניתוח:">
                <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                  :clearable="true" :optionsAsync="getExerciseSubjects" v-model="question.questionSubjectId" />
              </b-field>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center; margin-bottom: 5px; gap: 5px">
                <span class="bold-text">הגבלת זמן:</span><b-input type="number"
                  v-model="question.timeToRedAlertInSeconds"></b-input>שניות.
                *לאחר זמן מה השעון בתרגול יצבע באדום
              </div>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                <b-checkbox class="bold-text" v-model="question.isInExam">האם שאלת אמת</b-checkbox>
              </div>
              <b-input placeholder="MM/YYYY" v-if="question.isInExam" style="width: 30%;" v-model="question.examDate"
                type="text" v-mask="'##/####'"></b-input>
            </div>

            <div class="item">
              <b-checkbox class="bold-text" v-model="question.isDelta">האם שאלת דלתא</b-checkbox>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center; margin-bottom: 5px; gap: 5px">
                <span class="bold-text">מספרי פסקאות:</span>
                <b-input type="number" v-model="question.paragraphNumber1"></b-input>
                <b-input type="number" v-model="question.paragraphNumber2"></b-input>
                <b-input type="number" v-model="question.paragraphNumber3"></b-input>
                <b-input type="number" v-model="question.paragraphNumber4"></b-input>
                <b-input type="number" v-model="question.paragraphNumber5"></b-input>
              </div>
            </div>

            <div class="item">
              <b-field label="סעיפים נכונים:">
                <div style="display: flex; align-items: center; gap: 10px;">
                  <FieldInlineSelect @onChange="searchLawClause" :placeholder="'בחר חוק מהרשימה'" :hideHover="true"
                    style="border: 1px solid gray; width: 30%;" :filterable="true" :clearable="true"
                    :reduce="(law) => law" :optionsAsync="getLaws" v-model="question.law" />
                  <!-- <FieldInlineSelect v-if="question.law" :hideHover="true" style="border: 1px solid gray; width: 30%;"
                    :filterable="true" :clearable="true" :options="lawClauses" v-model="question.clauseId" /> -->
                  <v-select style="min-width: 300px;" v-if="question.law" dir="rtl" v-model="question.clauseId"
                    :filterable="true" :clearable="true" :reduce="item => item"
                    :getOptionLabel="(item) => `${item.Text}`" :options="lawClauses" :searchable="true"
                    :close-on-select="true" :placeholder="'בחר ערך מהרשימה'">
                  </v-select>
                  <button class="new" @click="addLawClause" v-if="question.clauseId">הוסף</button>
                </div>
                <div v-if="question.correctClauses && question.correctClauses.length" class="clauses">
                  <div style="display: flex; align-items: center; gap: 10px; margin-top: 10px"
                    v-for="clause, idx in question.correctClauses" :key="idx" class="clause">
                    <div>{{ clause.lawName }} סעיף: {{ clause.clauseName }}</div>
                    <button @click="removeLawClause(clause.clauseId)">X הסרה</button>
                  </div>
                </div>
              </b-field>
            </div>

            <!-- <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="question.status">האם לאפשר פתרון להדפסה</b-checkbox>
                <b-checkbox class="bold-text" v-model="question.status">האם לאפשר שאלון להדפסה</b-checkbox>
              </b-field>
            </div> -->

            <div v-if="question.answers" v-for="answer, idx in question.answers" :key="idx" class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                תשובה {{ idx + 1 }}
                <b-checkbox class="bold-text" v-model="answer.isCorrectAnswer">האם נכונה</b-checkbox>
              </div>
              <template>
                <!-- <ckeditor :editor="editor" v-model="answer.answer" :config="editorConfig"></ckeditor> -->
                <NewActivityEditor @onEditorChange="(e) => setAnswer(idx, e)" v-if="ready" :ckEditorContent="answer.answer" :content="answer.answer"
                  ref="editor" />
              </template>
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 30%;" label="מיומנות:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getMeyumanot" v-model="answer.skill" />
                </b-field>
                <b-field style="width: 30%;" label="חומרת שגיאה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="getSeverities" v-model="answer.severity" />
                </b-field>
                <b-field style="width: 60%;" label="הסבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getExplanations" v-model="answer.explanationId" />
                </b-field>
              </div>
              <div class="bold-text">
                הערה לתשובה
              </div>
              <template>
                <!-- <ckeditor :editor="editor" v-model="answer.remark" :config="editorConfig"></ckeditor> -->
                <NewActivityEditor @onEditorChange="(e) => setRemark(idx, e)" v-if="ready" :ckEditorContent="answer.remark" :content="answer.remark"
                  ref="editor" />
              </template>
            </div>

            <!-- <div class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                תשובה 1
                <b-checkbox class="bold-text" v-model="question.answer1IsCorrectAnswer">האם נכונה</b-checkbox>
              </div>
              <ckeditor :editor="editor" v-model="question.answer1Answer" :config="editorConfig"></ckeditor>
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 30%;" label="מיומנות:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="[{ Value: 0, Text: 'לא פעיל' }, { Value: 1, Text: 'פעיל' }]"
                    v-model="question.answer1Skill" />
                </b-field>
                <b-field style="width: 30%;" label="חומרת שגיאה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAcademicInstitudes" v-model="question.answer1Severity" />
                </b-field>
                <b-field style="width: 60%;" label="הסבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAuthors" v-model="question.answer1ExplainationId" />
                </b-field>
              </div>
              <div class="bold-text">
                הערה לתשובה
              </div>
              <ckeditor :editor="editor" v-model="question.answer1Remark" :config="editorConfig"></ckeditor>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                תשובה 2
                <b-checkbox class="bold-text" v-model="question.answer2IsCorrectAnswer">האם נכונה</b-checkbox>
              </div>
              <ckeditor :editor="editor" v-model="question.answer2Answer" :config="editorConfig"></ckeditor>
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 30%;" label="מיומנות:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="[{ Value: 0, Text: 'לא פעיל' }, { Value: 1, Text: 'פעיל' }]"
                    v-model="question.answer2Skill" />
                </b-field>
                <b-field style="width: 30%;" label="חומרת שגיאה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAcademicInstitudes" v-model="question.answer2Severity" />
                </b-field>
                <b-field style="width: 60%;" label="הסבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAuthors" v-model="question.answer2ExplainationId" />
                </b-field>
              </div>
              <div class="bold-text">
                הערה לתשובה
              </div>
              <ckeditor :editor="editor" v-model="question.answer2Remark" :config="editorConfig"></ckeditor>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                תשובה 3
                <b-checkbox class="bold-text" v-model="question.answer3IsCorrectAnswer">האם נכונה</b-checkbox>
              </div>
              <ckeditor :editor="editor" v-model="question.answer3Answer" :config="editorConfig"></ckeditor>
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 30%;" label="מיומנות:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="[{ Value: 0, Text: 'לא פעיל' }, { Value: 1, Text: 'פעיל' }]"
                    v-model="question.answer3Skill" />
                </b-field>
                <b-field style="width: 30%;" label="חומרת שגיאה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAcademicInstitudes" v-model="question.answer2Severity" />
                </b-field>
                <b-field style="width: 60%;" label="הסבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAuthors" v-model="question.answer3ExplainationId" />
                </b-field>
              </div>
              <div class="bold-text">
                הערה לתשובה
              </div>
              <ckeditor :editor="editor" v-model="question.answer3Remark" :config="editorConfig"></ckeditor>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                תשובה 4
                <b-checkbox class="bold-text" v-model="question.answer3IsCorrectAnswer">האם נכונה</b-checkbox>
              </div>
              <ckeditor :editor="editor" v-model="question.answer4Answer" :config="editorConfig"></ckeditor>
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 30%;" label="מיומנות:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="[{ Value: 0, Text: 'לא פעיל' }, { Value: 1, Text: 'פעיל' }]"
                    v-model="question.answer4Skill" />
                </b-field>
                <b-field style="width: 30%;" label="חומרת שגיאה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAcademicInstitudes" v-model="question.answer4Severity" />
                </b-field>
                <b-field style="width: 60%;" label="הסבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAuthors" v-model="question.answer4ExplainationId" />
                </b-field>
              </div>
              <div class="bold-text">
                הערה לתשובה
              </div>
              <ckeditor :editor="editor" v-model="question.answer4Remark" :config="editorConfig"></ckeditor>
            </div> -->

            <div class="item">
              <b-field label="הערות עורך:">
                <textarea type="text" v-model="question.editorNotes" style="border: 1px solid gray; width: 30%;"
                  rows="4" cols="50" name="" id=""></textarea>
              </b-field>
            </div>

            <div class="item">
              <div class="bold-text">
                (5 אחרונים) שאלה זו מופיעה בתרגולים
              </div>
              <div v-if="question.firstAppearance" class="question-instance">
                <div class="bold-text">מופע ראשון:</div>
                <a href="">{{ question.firstAppearance.exerciseName }}</a>
                <div>מתוך: {{ question.firstAppearance.lessonName }} בקורס: {{ question.firstAppearance.courseName }}
                </div>
              </div>
              <div class="question-instances">
                <div v-for="instance in question.lastFiveAppearances" :key="instance.id" class="question-instance">
                  <a href="">{{ instance.exerciseName }}</a>
                  <div>מתוך: {{ instance.lessonName }} בקורס: {{ instance.courseName }}</div>
                </div>
              </div>
            </div>

            <div class="question-save-buttons">
              <b-button type="is-info" class="new flex-2" @click="saveQuestion">שמירת שינויים</b-button>
              <!-- <b-button type="is-info" class="new flex-2" @click="newQuestion">הוספת שאלה חדשה לבנק השאלות</b-button> -->
            </div>

          </div>
        </div>
      </div>
      <!-- <div v-if="tab === 2">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <div class="new bold-text" @click="newVideo">רמת קושי ממוצעת בתרגול: {{ question.status || 6.5 }}</div>
          </div>
          <div class="section-container">
            <div style="display: flex; gap: 10px; margin-bottom: 10px;">
              <div class="bold-text">שאלות בתרגול</div>
              <b-button style="height: 25px;">הסתר חלופות</b-button>
              <b-button style="height: 25px;">הצג חלופות</b-button>
            </div>
            <small>מספר שאלות: {{ question.questions || 40 }} (*לא ניתן לסדר שאלות לתרגול פעיל)</small>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  סדר
                </div>
                <div class="column">
                  ID שאלה
                </div>
                <div class="column">
                  שאלה
                </div>
                <div class="column">
                  הערות עורך
                </div>
                <div class="column">
                  רמת קושי
                </div>
                <div class="column">

                </div>
                <div class="column">

                </div>
                <div class="column">

                </div>
              </div>
              <div class="row" v-for="(question, idx) in questionItems" :key="idx">
                <div class="column-item">{{ question.sortOrder }}</div>
                <div class="column-item">{{ question.id }}</div>
                <div class="column-item">{{ question.name }}</div>
                <div class="column-item">{{ question.editor }}</div>
                <div class="column-item">{{ question.difficulty }}</div>
                <div>
                  <b-tooltip type="is-white" label="הגדרות">
                    <a style="color: green;" class="updateItem">
                      <b-icon icon="file"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
                <div>
                  <b-tooltip type="is-white" label="הגדרות">
                    <a style="color: green;" class="updateItem">
                      <b-icon icon="file"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
                <div>
                  <b-tooltip type="is-white" label="הגדרות">
                    <a style="color: green;" class="updateItem">
                      <b-icon icon="file"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <b-field label="בחר שאלה">
              <FieldInlineSelect style="width: 30%; background-color: #eee;" :closeOnSelect="false"
                :alert-not-exist="true" :hide-hover="true" label="" :multiple="true" :clearable="true" :filterable="true"
                placeholder="חיפוש חופשי" :options="[]" :fetch-options="onSearchNewQuestions" @onChange="onChange"
                :reduce="(item) => item" v-model="newQuestionIds" />
            </b-field>
          </div>
        </template>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Tree from 'vuejs-tree'
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CoursesService from "@/services/CoursesService";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import _ from "lodash";
import {
  ModalProgrammatic as Modal,
  ToastProgrammatic as Toast
} from "buefy";
import DialogAddExercise from "./DialogAddExercise.vue";
import AdminService from "../services/AdminService";
import NewActivityEditor from "./NewActivityEditor.vue";

const {
  ClassicEditor,
  Alignment,
  Autoformat,
  Base64UploadAdapter,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  BlockQuote,
  Bold,
  Bookmark,
  CloudServices,
  Code,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  HtmlComment,
  HtmlEmbed,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  //
  MediaEmbed,
  Paragraph,
  PasteFromMarkdownExperimental,
  PasteFromOffice,
  RemoveFormat,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Style,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline
} = CKEDITOR;

export default {
  name: "Activity",
  components: {
    FieldInlineSelect,
    FieldInlineDate,
    vSelect,
    NewActivityEditor,
    Tree
  },
  props: {
    activities: Array,
    loading: Boolean,
    entityType: String,
    entityId: String,
  },
  // updated() {
  //   const params = window.location.search.substring(1);
  //   const lastIdx = params.lastIndexOf("=");
  //   const taskId = params.slice(lastIdx + 1);
  //   const task = this.$refs[`taskElement${taskId}`][0];
  //   task.isOpen = true;
  //   this.$nextTick(() => {
  //     task.$el.scrollIntoView({ behavior: "smooth" });
  //   });
  // },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    },
    myCustomStyles() {
      return {
        tree: {
        height: 'auto',
        maxHeight: '940px',
        overflowY: 'visible',
        display: 'inline-block',
        // textAlign: 'left'
      },
        row: {
          style: {
            width: "200px",
            cursor: "pointer",
          },
          child: {
            class: "",
            style: {
              height: "35px",
            },
            active: {
              style: {
                height: "35px",
              },
            },
          },
        },
        rowIndent: {
          paddingLeft: "10px",
        },
        text: {
          // class: "" // uncomment this line to overwrite the 'capitalize' class, or add a custom class
          style: {},
          active: {
            style: {
              "font-weight": "bold",
              color: "#2ECC71",
            },
          },
        },
      };
    },
    myCustomOptions() {
      return {
        treeEvents: {
          expanded: {
            state: false,
          },
          collapsed: {
            state: false,
          },
          selected: {
            state: true,
            fn: this.mySelectedFunction,
          },
          checked: {
            state: true,
            fn: this.myCheckedFunction,
          },
        },
        events: {
          expanded: {
            state: true,
          },
          // selected: {
          //   state: true,
          // },
          checked: {
            state: true,
          },
          editableName: {
            state: true,
            calledEvent: "expanded",
          },
        },
        // addNode: {
        //   state: true,
        //   fn: this.addNodeFunction,
        //   appearOnHover: false,
        // },
        // editNode: { state: false, fn: null, appearOnHover: false },
        // deleteNode: {
        //   state: true,
        //   fn: this.deleteNodeFunction,
        //   appearOnHover: true,
        // },
        // showTags: true,
      };
    },
    inputVal() {
      return new Date(this.question.examDate);
    },
    getEffectTypes() {
      return [
        { Value: 0, Text: 'שאלה רגילה' },
        { Value: 1, Text: 'Timer' },
        { Value: 2, Text: 'PopUp' },
        { Value: 3, Text: 'HideAnswers' },
        { Value: 4, Text: 'InterferenceQuestion' },
        { Value: 5, Text: 'PopUpQuestionText' },
      ];
    },
    getSeverities() {
      return [
        { Value: 1, Text: '1' },
        { Value: 2, Text: '2' },
        { Value: 3, Text: '3' },
        { Value: 4, Text: '4' },
        { Value: 5, Text: '5' },
      ];
    },
    lesson() {
      return this.$store.state.lesson;
    },
    userName() {
      return `${this.$store.state.user.privateName} ${this.$store.state.user.lastName}`;
    },
    haveNoActivities() {
      return (
        !this.loading &&
        (!this.groupedActivities || !Object.keys(this.groupedActivities).length)
      );
    },
    haveNoNotes() {
      return (
        !this.loading &&
        (!this.groupedActivitiesNotes ||
          !Object.keys(this.groupedActivitiesNotes).length)
      );
    },
    haveNoTasks() {
      return (
        !this.loading &&
        (!this.groupedActivitiesTasks ||
          !Object.keys(this.groupedActivitiesTasks).length)
      );
    },
    groupedActivities() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) => this.deletedItems.indexOf(item.Id) === -1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesNotes() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesTasks() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 2
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
  },
  data() {
    return {
      tab: 1,
      showNewNote: false,
      showNewTask: false,
      deletedItems: [],
      isLoading: false,
      ready: false,
      categories: [],
      lawClauses: [],
      question: {
        status: 1
      },
      treeCategories: [
        // {
        //   text: "root 1",
        //   state: { checked: false, selected: false, expanded: false },
        //   id: 1,
        //   checkable: true,
        //   nodes: [
        //     {
        //       text: "Child 1",
        //       state: { checked: true, selected: false, expanded: false },
        //       id: 3,
        //       nodes: [
        //         {
        //           text: "Grandchild 1",
        //           state: { checked: false, selected: false, expanded: false },
        //           id: 5,
        //         },
        //         {
        //           text: "Grandchild 2",
        //           state: { checked: false, selected: false, expanded: false },
        //           id: 6,
        //         },
        //       ],
        //     },
        //     {
        //       text: "Child 2",
        //       state: { checked: false, selected: false, expanded: false },
        //       id: 4,
        //     },
        //   ],
        // },
        // {
        //   text: "Root 2",
        //   state: { checked: false, selected: false, expanded: false },
        //   id: 2,
        // },
      ],
      questionItems: null,
      laws: null,
      verdicts: null,
      studentAnswers: {},
      newQuestionIds: null,
      editor: ClassicEditor,
      editorConfig: {
        language: 'he',
        toolbar: {
          items: [
            'heading',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'link',
            'insertTable',
            'highlight',
            '|',
            'alignment',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            'outdent',
            'indent'
          ],
          shouldNotGroupWhenFull: false
        },
        plugins: [
          Alignment,
          Autoformat,
          Base64UploadAdapter,
          AutoImage,
          AutoLink,
          Autosave,
          BalloonToolbar,
          BlockQuote,
          Bold,
          Bookmark,
          CloudServices,
          Code,
          Essentials,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          GeneralHtmlSupport,
          Heading,
          Highlight,
          HorizontalLine,
          HtmlComment,
          HtmlEmbed,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListProperties,
          //
          MediaEmbed,
          Paragraph,
          PasteFromMarkdownExperimental,
          PasteFromOffice,
          RemoveFormat,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Style,
          Subscript,
          Superscript,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextTransformation,
          TodoList,
          Underline
        ],
        balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
        fontFamily: {
          supportAllValues: true
        },
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22],
          supportAllValues: true
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3'
            },
            {
              model: 'heading4',
              view: 'h4',
              title: 'Heading 4',
              class: 'ck-heading_heading4'
            },
            {
              model: 'heading5',
              view: 'h5',
              title: 'Heading 5',
              class: 'ck-heading_heading5'
            },
            {
              model: 'heading6',
              view: 'h6',
              title: 'Heading 6',
              class: 'ck-heading_heading6'
            }
          ]
        },
        htmlSupport: {
          allow: [
            {
              name: /^.*$/,
              styles: true,
              attributes: true,
              classes: true
            }
          ]
        },
        image: {
          toolbar: [
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
            '|',
            'resizeImage'
          ]
        },
        licenseKey: 'eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NjcxMzkxOTksImp0aSI6IjAzMzQyMjlmLTMxYmUtNGU2MC1hNjgyLTIzOGU5YWE1M2MxMCIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiXSwiZmVhdHVyZXMiOlsiRFJVUCJdLCJ2YyI6IjY5N2VlNDZmIn0.j0Qq4l_jAjCruUz_bUcn9aWq2HwWwi-ffnXv4z6aYIvjG5tapML2zQCf2ArFDo5s9qFnawNwpkE9_rXOa7gjUA',
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: 'https://',
          decorators: {
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            }
          }
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        menuBar: {
          isVisible: true
        },
        placeholder: '',
        style: {
          definitions: [
            {
              name: 'Article category',
              element: 'h3',
              classes: ['category']
            },
            {
              name: 'Title',
              element: 'h2',
              classes: ['document-title']
            },
            {
              name: 'Subtitle',
              element: 'h3',
              classes: ['document-subtitle']
            },
            {
              name: 'Info box',
              element: 'p',
              classes: ['info-box']
            },
            {
              name: 'Side quote',
              element: 'blockquote',
              classes: ['side-quote']
            },
            {
              name: 'Marker',
              element: 'span',
              classes: ['marker']
            },
            {
              name: 'Spoiler',
              element: 'span',
              classes: ['spoiler']
            },
            {
              name: 'Code (dark)',
              element: 'pre',
              classes: ['fancy-code', 'fancy-code-dark']
            },
            {
              name: 'Code (bright)',
              element: 'pre',
              classes: ['fancy-code', 'fancy-code-bright']
            }
          ]
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
        }
      },
    };
  },
  created() {
    this.getCategories();
    this.loadQuestion();
    this.loadQuestionStudentAnswers();
  },
  methods: {
    onDelete(id) {
      this.deletedItems.push(id);
    },
    setQuestion(value) {
      this.question.question = value;
    },
    setDetailed(value) {
      this.question.detailedAnswer = value;
    },
    setAnswer(idx, value) {
      this.question.answers[idx].answer = value;
    },
    setRemark(idx, value) {
      this.question.answers[idx].remark = value;
    },
    onChange() { },
    removeLawClause(clauseId) {
      const idx = this.question.correctClauses.findIndex((c) => c.clauseId === clauseId);
      this.question.correctClauses.splice(idx, 1);
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    parseTime(time) {
      return `${time.hours <= 9 ? `0${time.hours}` : time.hours}:${time.minutes <= 9 ? `0${time.minutes}` : time.minutes}:${time.seconds <= 9 ? `0${time.seconds}` : time.seconds}`;
    },
    onSearchNewQuestions({ search, loading, setData, setValue }) {
      if (search.length > 3) {
        loading(true);
        return AdminService.getQuestionsInBank({
          Terms: search,
          PageSize: 999,
          IsAscending: false,
          PageNumber: 1,
          ResultType: 3
        })
          .then((r) => {
            if (!r.data.items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.items.map((i) => ({ Value: i.id, Text: `${i.question} - ${i.id}` })));
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    getMeyumanot() {
      return () =>
        CoursesService.getMeyumanot().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.skillname }))
        );
    },
    getExerciseSubjects() {
      return () =>
        CoursesService.getExerciseSubjects().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.subject }))
        );
    },
    getCategories() {
      CoursesService.getCategories().then((r) => {
        this.categories = r.data.map((i) => ({ Value: i.id, Text: i.name })),
          this.treeCategories = r.data.map((c) => ({ id: c.id, text: c.name }))
      }
      );
    },
    getLaws() {
      return () =>
        CoursesService.getLaws().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.dymanicId != null ? i.name + "*" : i.name }))
        );
    },
    getExplanations() {
      return () =>
        CoursesService.getExplanations().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.answerExplenationData }))
        );
    },
    getLawClauses() {
      CoursesService.getLawClauses(this.question.law.Value).then((r) => {
        this.lawClauses = r.data.map((i) => ({ Value: i.id, Text: i.name }));
      });
    },
    addLawClause() {
      this.question.correctClauses.push({
        lawId: this.question.law.Value,
        lawName: this.question.law.Text,
        clauseId: this.question.clauseId.Value,
        clauseName: this.question.clauseId.Text
      });
      this.question.lawId = null;
      this.question.clauseId = null;
    },
    searchLawClause() {
      this.getLawClauses();
    },
    getAcademicInstitudes() {
      return () =>
        AdminService.getAcademicInstitudes().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getAuthors() {
      return () =>
        AdminService.getAuthors().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    close() {
      this.showNewNote = false;
    },
    closeTask() {
      this.showNewTask = false;
    },
    newNote() {
      this.showNewNote = true;
    },
    newTask() {
      this.showNewTask = true;
    },
    newQuestion() {
      Modal.open({
        component: DialogAddExercise,
        customClass: "entity-modal",
        props: {

        },
        canCancel: false,
        trapFocus: true,
      });
    },
    saveQuestion() {
      this.isLoading = true;
      // this.question.question = this.$refs.question.getHTML();
      // this.question.detailedAnswer = this.$refs.detailedAnswer.getHTML();
      AdminService.updateQuestion(this.question.id, {
        ...this.question
      }).then((r) => {
        // this.question = r.data;
        Toast.open({
          type: "is-success",
          message: "העדכון בוצע בהצלחה!",
          duration: 8000,
        });
        window.location.reload();
      }).catch(() => {
        Toast.open({
          type: "is-danger",
          message: "השיוך נכשל!",
          duration: 8000,
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
    loadQuestion() {
      this.isLoading = true;
      AdminService.getQuestionDetails(this.entityId).then((r) => {
        this.question = r.data;
        this.ready = true;
        this.question.paragraphNumber1 = this.question.paragraphNumbers.split(',')[0];
        this.question.paragraphNumber2 = this.question.paragraphNumbers.split(',')[1];
        this.question.paragraphNumber3 = this.question.paragraphNumbers.split(',')[2];
        this.question.paragraphNumber4 = this.question.paragraphNumbers.split(',')[3];
        this.question.paragraphNumber5 = this.question.paragraphNumbers.split(',')[4];
      })
        .finally(() => {
          this.tab = 1;
          this.isLoading = false;
        });
    },
    loadQuestionStudentAnswers() {
      AdminService.getQuestionStudentAnswers(this.entityId).then((r) => {
        this.studentAnswers = r.data;
      });
    },
    loadQuestionItems() {
      this.isLoading = true;
      AdminService.getQuestionDetails(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.questionItems = r.data.items;
        })
        .finally(() => {
          this.tab = 2;
          this.isLoading = false;
        });
    },
    loadLaws() {
      this.isLoading = true;
      AdminService.getAdminLaws(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.laws = r.data.items;
        })
        .finally(() => {
          this.tab = 3;
          this.isLoading = false;
        });
    },
    loadVerdicts() {
      this.isLoading = true;
      AdminService.getAdminVerdicts(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.verdicts = r.data.items;
        })
        .finally(() => {
          this.tab = 4;
          this.isLoading = false;
        });
    },
    parsedGroupedDate(date) {
      if (date) {
        const localLocale = moment(date, "MM/YYYY");
        localLocale.locale("he");
        return localLocale.format("MMMM YYYY");
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.Activity__Component {
  background-color: #fff;
}

::v-deep .row_data span {
  margin-left: 5px;
}

.question-details {
  display: flex;
  margin-top: 20px;
  // justify-content: space-between;
}

.question-detail {
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    width: 350px;
  }
}

.question-instance {
  display: flex;
  align-items: center;
  gap: 10px;
}

.question-buttons {
  display: flex;
  justify-content: space-between;
}

.item {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding: 20px 10px;
  background-color: white;
}

.bold-text {
  font-weight: bold;
}

.Activity__Component__Tabs {
  display: flex;
  border-bottom: 1px solid #cbd6e2;
  margin: 0px 20px;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #fff;
  padding-top: 6px;

  .Activity__Component__Tabs__Item {
    padding: 15px 25px;
    position: relative;
    cursor: pointer;
    font-size: 15px;

    &:not(.active):hover {
      color: #4188b7;
    }

    &.active:after {
      content: "";
      width: 100%;
      position: absolute;
      z-index: 2;
      bottom: -3px;
      background-color: #2e3f50;
      height: 5px;
      right: 0;
      border-radius: 3px;
    }
  }
}

.section-container {
  display: flex;
  flex-direction: column;
  margin: 0 2% 50px 3%;

  // overflow-x: hidden;
  // align-items: center;
  &:last-child {
    // margin: 0 5% 0 5%;
  }

  img {
    height: 15px;
    margin: 0 2px;
  }

  h1 {
    // margin: 0 2%;
    // width: 15%;
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }

  p {
    padding: 0px 1%;
    width: 83%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-break: break-word;

    ::v-deep .title {
      display: none;
    }
  }

  .workplan {
    display: flex;
    flex-direction: column;

    span {
      // display: flex;
      // align-items: center;
      width: fit-content;
    }

  }
}

.document-table-title-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  // margin-bottom: 25px;
  .document-table-title {
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
  }

  .label-upload-file {
    margin-left: 20px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    color: #57b7ca;
    cursor: pointer;
  }
}

.document-table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 70px;
  border: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 5px;
  margin-top: 15px;

  .columns {
    font-weight: bold;
    background-color: #E98F12;
    text-align: center;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
    }

    &>div {
      display: table-cell;
      padding: 10px 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    .Table_Field {
      min-width: 170px;
    }
  }
}

.Activity__Component__TabsContent {
  padding: 20px 30px;
  background-color: #eeeeee;

  & .Activity__Item {
    margin: 8px 0;
  }
}

.loading {
  max-height: 300px;
  width: 600px;
  margin: 0 auto;
}

.no-activities {
  font-size: 25px;
  text-align: center;
  opacity: 0.8;

  span {
    margin-top: 50px;

    ::v-deep .mdi-timeline-text-outline {
      &::before {
        font-size: 50px;
      }
    }
  }
}

button.new {
  background-color: #3b5671;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 18px;
  // cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: #4d6782;
  }
}

.question-save-buttons {
  display: flex;
  justify-content: space-around;
  width: 30%;
  gap: 20px;
  margin-top: 15px;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.Activity__Component__TabsContent__Tasks__Header {
  display: flex;
  justify-content: flex-end;
}

.Activity__Component__TabsContent__Tasks__Content {
  h1 {
    text-align: center;
  }
}
</style>
