var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Activity__Component" }, [
    _c("div", { staticClass: "Activity__Component__Tabs" }, [
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 1 },
          on: {
            click: function($event) {
              return _vm.loadQuestion()
            }
          }
        },
        [_vm._v(" עריכה ")]
      )
    ]),
    _c(
      "div",
      { staticClass: "Activity__Component__TabsContent" },
      [
        _vm.isLoading
          ? [
              _c(
                "div",
                { staticClass: "loading" },
                [
                  _c("b-loading", {
                    attrs: { "is-full-page": false, "can-cancel": false },
                    model: {
                      value: _vm.isLoading,
                      callback: function($$v) {
                        _vm.isLoading = $$v
                      },
                      expression: "isLoading"
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        _vm.tab === 1
          ? _c("div", [
              _c(
                "div",
                { staticClass: "Activity__Component__TabsContent__Tasks" },
                [
                  _vm.question
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "Activity__Component__TabsContent__Tasks__Content"
                        },
                        [
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "h1",
                              {
                                staticClass: "bold-text",
                                staticStyle: { display: "flex" }
                              },
                              [_vm._v("נתוני חכמת ההמונים")]
                            ),
                            _c("div", { staticClass: "question-details" }, [
                              _c(
                                "div",
                                { staticClass: "question-detail" },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      title:
                                        "1- הכי קלה, 10-הכי קשה. נתון זה מתבסס על חכמת ההמונים",
                                      pack: "fa",
                                      icon: "exclamation"
                                    }
                                  }),
                                  _c("p", [
                                    _vm._v(
                                      "רמת קושי: " +
                                        _vm._s(_vm.question.difficultyLevel) +
                                        " (" +
                                        _vm._s(
                                          _vm.studentAnswers
                                            .difficultyPercentage
                                        ) +
                                        "% הצלחה)"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "question-detail" },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      title: "כמות משיבים כללית לשאלה זו",
                                      pack: "fa",
                                      icon: "exclamation"
                                    }
                                  }),
                                  _c("p", [
                                    _vm._v(
                                      "כמות משיבים: " +
                                        _vm._s(
                                          _vm.studentAnswers.studentAnswersCount
                                        )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "question-detail" },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      title: "זמן ממוצע נטו למענה על שאלה זו",
                                      pack: "fa",
                                      icon: "exclamation"
                                    }
                                  }),
                                  _vm.studentAnswers.averageQuestionTime &&
                                  _vm.question.averageQuestionTime
                                    ? _c("p", [
                                        _vm._v(
                                          "זמן ממוצע למענה: " +
                                            _vm._s(
                                              _vm.parseTime(
                                                _vm.studentAnswers
                                                  .averageQuestionTime
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "question-details" }, [
                              _c(
                                "div",
                                { staticClass: "question-detail" },
                                [
                                  _c("b-icon", {
                                    attrs: { pack: "fa", icon: "exclamation" }
                                  }),
                                  _c("p", [
                                    _vm._v(
                                      "אחוז סימוני כוכבית: " +
                                        _vm._s(
                                          _vm.studentAnswers.starPercentage
                                        ) +
                                        "%"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "question-detail" },
                                [
                                  _c("b-icon", {
                                    attrs: { pack: "fa", icon: "exclamation" }
                                  }),
                                  _c("p", [
                                    _vm._v(
                                      "ציון כוכבית: " +
                                        _vm._s(
                                          _vm.studentAnswers.starGrade ||
                                            "אין נתונים"
                                        )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "question-detail" },
                                [
                                  _c("b-icon", {
                                    attrs: { pack: "fa", icon: "exclamation" }
                                  }),
                                  _c("p", [
                                    _vm._v(
                                      "אחוז אפורות: " +
                                        _vm._s(
                                          _vm.studentAnswers.grayPercentage
                                        )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "question-detail" },
                                [
                                  _c("b-icon", {
                                    attrs: { pack: "fa", icon: "exclamation" }
                                  }),
                                  _c("p", [
                                    _vm._v(
                                      "ציון אפורות: " +
                                        _vm._s(_vm.studentAnswers.grayGrade)
                                    )
                                  ])
                                ],
                                1
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "div",
                              { staticStyle: { display: "flex", gap: "20px" } },
                              [
                                _c(
                                  "b-field",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { label: "סטטוס:" }
                                  },
                                  [
                                    _c("FieldInlineSelect", {
                                      staticStyle: {
                                        border: "1px solid gray",
                                        width: "100%"
                                      },
                                      attrs: {
                                        hideHover: true,
                                        filterable: true,
                                        clearable: true,
                                        options: [
                                          { Value: false, Text: "לא פעיל" },
                                          { Value: true, Text: "פעיל" }
                                        ]
                                      },
                                      model: {
                                        value: _vm.question.status,
                                        callback: function($$v) {
                                          _vm.$set(_vm.question, "status", $$v)
                                        },
                                        expression: "question.status"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-field",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { label: "מוסד:" }
                                  },
                                  [
                                    _c("FieldInlineSelect", {
                                      staticStyle: {
                                        border: "1px solid gray",
                                        width: "100%"
                                      },
                                      attrs: {
                                        hideHover: true,
                                        filterable: true,
                                        clearable: true,
                                        optionsAsync: _vm.getAcademicInstitudes
                                      },
                                      model: {
                                        value:
                                          _vm.question.academicInstitutionId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.question,
                                            "academicInstitutionId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "question.academicInstitutionId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-field",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { label: "מחבר:" }
                                  },
                                  [
                                    _c("FieldInlineSelect", {
                                      staticStyle: {
                                        border: "1px solid gray",
                                        width: "100%"
                                      },
                                      attrs: {
                                        hideHover: true,
                                        filterable: true,
                                        clearable: true,
                                        optionsAsync: _vm.getAuthors
                                      },
                                      model: {
                                        value: _vm.question.authorId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.question,
                                            "authorId",
                                            $$v
                                          )
                                        },
                                        expression: "question.authorId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c(
                                      "b-field",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { label: "נוצר על ידי:" }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.question.creatorUserName
                                            ) +
                                              " (" +
                                              _vm._s(
                                                _vm.parseDate(
                                                  _vm.question.createdDate
                                                )
                                              ) +
                                              ")"
                                          )
                                        ])
                                      ]
                                    ),
                                    _c("a", [_vm._v("היסטוריית עריכה")])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "div",
                              { staticStyle: { display: "flex", gap: "20px" } },
                              [
                                _c(
                                  "b-field",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { label: "סוג שאלה:" }
                                  },
                                  [
                                    _c("FieldInlineSelect", {
                                      staticStyle: {
                                        border: "1px solid gray",
                                        width: "100%"
                                      },
                                      attrs: {
                                        hideHover: true,
                                        filterable: true,
                                        clearable: true,
                                        options: [
                                          { Value: 1, Text: "Default" },
                                          { Value: 4, Text: "טקסט" },
                                          { Value: 5, Text: "YesNo" }
                                        ]
                                      },
                                      model: {
                                        value:
                                          _vm.question.exerciseQuestionTypeId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.question,
                                            "exerciseQuestionTypeId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "question.exerciseQuestionTypeId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-field",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { label: "שם שאלה:" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.question.name,
                                          expression: "question.name"
                                        }
                                      ],
                                      staticStyle: {
                                        border: "1px solid gray",
                                        width: "100%",
                                        padding: "14px"
                                      },
                                      attrs: { type: "text", name: "", id: "" },
                                      domProps: { value: _vm.question.name },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.question,
                                            "name",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "b-field",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { label: "תיאור קצר:" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.question.questionDescription,
                                          expression:
                                            "question.questionDescription"
                                        }
                                      ],
                                      staticStyle: {
                                        border: "1px solid gray",
                                        width: "100%",
                                        padding: "14px"
                                      },
                                      attrs: { type: "text", name: "", id: "" },
                                      domProps: {
                                        value: _vm.question.questionDescription
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.question,
                                            "questionDescription",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "b-field",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { label: "קטגוריות:" }
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        dir: "rtl",
                                        label: "Text",
                                        filterable: true,
                                        clearable: true,
                                        reduce: function(item) {
                                          return item.Value
                                        },
                                        getOptionLabel: function(item) {
                                          return "" + item.Text
                                        },
                                        options: _vm.categories,
                                        multiple: true,
                                        searchable: true,
                                        "close-on-select": false,
                                        placeholder: "בחר ערך מהרשימה"
                                      },
                                      model: {
                                        value: _vm.question.exerciseCategoryIds,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.question,
                                            "exerciseCategoryIds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "question.exerciseCategoryIds"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "שאלה" } },
                                [
                                  [
                                    _vm.ready
                                      ? _c("NewActivityEditor", {
                                          ref: "question",
                                          attrs: {
                                            ckEditorContent:
                                              _vm.question.question,
                                            content: _vm.question.question
                                          },
                                          on: {
                                            onEditorChange: _vm.setQuestion
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "תשובה מפורטת" } },
                                [
                                  [
                                    _vm.ready
                                      ? _c("NewActivityEditor", {
                                          ref: "detailedAnswer",
                                          attrs: {
                                            ckEditorContent:
                                              _vm.question.detailedAnswer,
                                            content: _vm.question.detailedAnswer
                                          },
                                          on: {
                                            onEditorChange: _vm.setDetailed
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "b-field",
                                  {
                                    staticStyle: { width: "50%" },
                                    attrs: { label: "סוג אפקט בשאלה:" }
                                  },
                                  [
                                    _c("FieldInlineSelect", {
                                      staticStyle: {
                                        border: "1px solid gray",
                                        width: "100%"
                                      },
                                      attrs: {
                                        hideHover: true,
                                        filterable: true,
                                        clearable: true,
                                        options: _vm.getEffectTypes
                                      },
                                      model: {
                                        value: _vm.question.effectId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.question,
                                            "effectId",
                                            $$v
                                          )
                                        },
                                        expression: "question.effectId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-checkbox",
                                  {
                                    staticClass: "bold-text",
                                    model: {
                                      value: _vm.question.isHideAnswers,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.question,
                                          "isHideAnswers",
                                          $$v
                                        )
                                      },
                                      expression: "question.isHideAnswers"
                                    }
                                  },
                                  [_vm._v("הסתרת חלופות כדיפולט?")]
                                )
                              ],
                              1
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "נושא שאלה לדוחות ניתוח:" } },
                                [
                                  _c("FieldInlineSelect", {
                                    staticStyle: {
                                      border: "1px solid gray",
                                      width: "100%"
                                    },
                                    attrs: {
                                      hideHover: true,
                                      filterable: true,
                                      clearable: true,
                                      optionsAsync: _vm.getExerciseSubjects
                                    },
                                    model: {
                                      value: _vm.question.questionSubjectId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.question,
                                          "questionSubjectId",
                                          $$v
                                        )
                                      },
                                      expression: "question.questionSubjectId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "margin-bottom": "5px",
                                  gap: "5px"
                                }
                              },
                              [
                                _c("span", { staticClass: "bold-text" }, [
                                  _vm._v("הגבלת זמן:")
                                ]),
                                _c("b-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.question.timeToRedAlertInSeconds,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.question,
                                        "timeToRedAlertInSeconds",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "question.timeToRedAlertInSeconds"
                                  }
                                }),
                                _vm._v(
                                  "שניות. *לאחר זמן מה השעון בתרגול יצבע באדום "
                                )
                              ],
                              1
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "bold-text",
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "bold-text",
                                      model: {
                                        value: _vm.question.isInExam,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.question,
                                            "isInExam",
                                            $$v
                                          )
                                        },
                                        expression: "question.isInExam"
                                      }
                                    },
                                    [_vm._v("האם שאלת אמת")]
                                  )
                                ],
                                1
                              ),
                              _vm.question.isInExam
                                ? _c("b-input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/####",
                                        expression: "'##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "30%" },
                                    attrs: {
                                      placeholder: "MM/YYYY",
                                      type: "text"
                                    },
                                    model: {
                                      value: _vm.question.examDate,
                                      callback: function($$v) {
                                        _vm.$set(_vm.question, "examDate", $$v)
                                      },
                                      expression: "question.examDate"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-checkbox",
                                {
                                  staticClass: "bold-text",
                                  model: {
                                    value: _vm.question.isDelta,
                                    callback: function($$v) {
                                      _vm.$set(_vm.question, "isDelta", $$v)
                                    },
                                    expression: "question.isDelta"
                                  }
                                },
                                [_vm._v("האם שאלת דלתא")]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "margin-bottom": "5px",
                                  gap: "5px"
                                }
                              },
                              [
                                _c("span", { staticClass: "bold-text" }, [
                                  _vm._v("מספרי פסקאות:")
                                ]),
                                _c("b-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.question.paragraphNumber1,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.question,
                                        "paragraphNumber1",
                                        $$v
                                      )
                                    },
                                    expression: "question.paragraphNumber1"
                                  }
                                }),
                                _c("b-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.question.paragraphNumber2,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.question,
                                        "paragraphNumber2",
                                        $$v
                                      )
                                    },
                                    expression: "question.paragraphNumber2"
                                  }
                                }),
                                _c("b-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.question.paragraphNumber3,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.question,
                                        "paragraphNumber3",
                                        $$v
                                      )
                                    },
                                    expression: "question.paragraphNumber3"
                                  }
                                }),
                                _c("b-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.question.paragraphNumber4,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.question,
                                        "paragraphNumber4",
                                        $$v
                                      )
                                    },
                                    expression: "question.paragraphNumber4"
                                  }
                                }),
                                _c("b-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.question.paragraphNumber5,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.question,
                                        "paragraphNumber5",
                                        $$v
                                      )
                                    },
                                    expression: "question.paragraphNumber5"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "סעיפים נכונים:" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        gap: "10px"
                                      }
                                    },
                                    [
                                      _c("FieldInlineSelect", {
                                        staticStyle: {
                                          border: "1px solid gray",
                                          width: "30%"
                                        },
                                        attrs: {
                                          placeholder: "בחר חוק מהרשימה",
                                          hideHover: true,
                                          filterable: true,
                                          clearable: true,
                                          reduce: function(law) {
                                            return law
                                          },
                                          optionsAsync: _vm.getLaws
                                        },
                                        on: { onChange: _vm.searchLawClause },
                                        model: {
                                          value: _vm.question.law,
                                          callback: function($$v) {
                                            _vm.$set(_vm.question, "law", $$v)
                                          },
                                          expression: "question.law"
                                        }
                                      }),
                                      _vm.question.law
                                        ? _c("v-select", {
                                            staticStyle: {
                                              "min-width": "300px"
                                            },
                                            attrs: {
                                              dir: "rtl",
                                              filterable: true,
                                              clearable: true,
                                              reduce: function(item) {
                                                return item
                                              },
                                              getOptionLabel: function(item) {
                                                return "" + item.Text
                                              },
                                              options: _vm.lawClauses,
                                              searchable: true,
                                              "close-on-select": true,
                                              placeholder: "בחר ערך מהרשימה"
                                            },
                                            model: {
                                              value: _vm.question.clauseId,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.question,
                                                  "clauseId",
                                                  $$v
                                                )
                                              },
                                              expression: "question.clauseId"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.question.clauseId
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "new",
                                              on: { click: _vm.addLawClause }
                                            },
                                            [_vm._v("הוסף")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm.question.correctClauses &&
                                  _vm.question.correctClauses.length
                                    ? _c(
                                        "div",
                                        { staticClass: "clauses" },
                                        _vm._l(
                                          _vm.question.correctClauses,
                                          function(clause, idx) {
                                            return _c(
                                              "div",
                                              {
                                                key: idx,
                                                staticClass: "clause",
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                  gap: "10px",
                                                  "margin-top": "10px"
                                                }
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(clause.lawName) +
                                                      " סעיף: " +
                                                      _vm._s(clause.clauseName)
                                                  )
                                                ]),
                                                _c(
                                                  "button",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeLawClause(
                                                          clause.clauseId
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("X הסרה")]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.question.answers, function(answer, idx) {
                            return _vm.question.answers
                              ? _c(
                                  "div",
                                  { key: idx, staticClass: "item" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "bold-text",
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " תשובה " + _vm._s(idx + 1) + " "
                                        ),
                                        _c(
                                          "b-checkbox",
                                          {
                                            staticClass: "bold-text",
                                            model: {
                                              value: answer.isCorrectAnswer,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  answer,
                                                  "isCorrectAnswer",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "answer.isCorrectAnswer"
                                            }
                                          },
                                          [_vm._v("האם נכונה")]
                                        )
                                      ],
                                      1
                                    ),
                                    [
                                      _vm.ready
                                        ? _c("NewActivityEditor", {
                                            ref: "editor",
                                            refInFor: true,
                                            attrs: {
                                              ckEditorContent: answer.answer,
                                              content: answer.answer
                                            },
                                            on: {
                                              onEditorChange: function(e) {
                                                return _vm.setAnswer(idx, e)
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          gap: "20px"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-field",
                                          {
                                            staticStyle: { width: "30%" },
                                            attrs: { label: "מיומנות:" }
                                          },
                                          [
                                            _c("FieldInlineSelect", {
                                              staticStyle: {
                                                border: "1px solid gray",
                                                width: "100%"
                                              },
                                              attrs: {
                                                hideHover: true,
                                                filterable: true,
                                                clearable: true,
                                                optionsAsync: _vm.getMeyumanot
                                              },
                                              model: {
                                                value: answer.skill,
                                                callback: function($$v) {
                                                  _vm.$set(answer, "skill", $$v)
                                                },
                                                expression: "answer.skill"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-field",
                                          {
                                            staticStyle: { width: "30%" },
                                            attrs: { label: "חומרת שגיאה:" }
                                          },
                                          [
                                            _c("FieldInlineSelect", {
                                              staticStyle: {
                                                border: "1px solid gray",
                                                width: "100%"
                                              },
                                              attrs: {
                                                hideHover: true,
                                                filterable: true,
                                                clearable: true,
                                                options: _vm.getSeverities
                                              },
                                              model: {
                                                value: answer.severity,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    answer,
                                                    "severity",
                                                    $$v
                                                  )
                                                },
                                                expression: "answer.severity"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-field",
                                          {
                                            staticStyle: { width: "60%" },
                                            attrs: { label: "הסבר:" }
                                          },
                                          [
                                            _c("FieldInlineSelect", {
                                              staticStyle: {
                                                border: "1px solid gray",
                                                width: "100%"
                                              },
                                              attrs: {
                                                hideHover: true,
                                                filterable: true,
                                                clearable: true,
                                                optionsAsync:
                                                  _vm.getExplanations
                                              },
                                              model: {
                                                value: answer.explanationId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    answer,
                                                    "explanationId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "answer.explanationId"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "bold-text" }, [
                                      _vm._v(" הערה לתשובה ")
                                    ]),
                                    [
                                      _vm.ready
                                        ? _c("NewActivityEditor", {
                                            ref: "editor",
                                            refInFor: true,
                                            attrs: {
                                              ckEditorContent: answer.remark,
                                              content: answer.remark
                                            },
                                            on: {
                                              onEditorChange: function(e) {
                                                return _vm.setRemark(idx, e)
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  ],
                                  2
                                )
                              : _vm._e()
                          }),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "הערות עורך:" } },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.question.editorNotes,
                                        expression: "question.editorNotes"
                                      }
                                    ],
                                    staticStyle: {
                                      border: "1px solid gray",
                                      width: "30%"
                                    },
                                    attrs: {
                                      type: "text",
                                      rows: "4",
                                      cols: "50",
                                      name: "",
                                      id: ""
                                    },
                                    domProps: {
                                      value: _vm.question.editorNotes
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.question,
                                          "editorNotes",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "item" }, [
                            _c("div", { staticClass: "bold-text" }, [
                              _vm._v(" (5 אחרונים) שאלה זו מופיעה בתרגולים ")
                            ]),
                            _vm.question.firstAppearance
                              ? _c(
                                  "div",
                                  { staticClass: "question-instance" },
                                  [
                                    _c("div", { staticClass: "bold-text" }, [
                                      _vm._v("מופע ראשון:")
                                    ]),
                                    _c("a", { attrs: { href: "" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.question.firstAppearance
                                            .exerciseName
                                        )
                                      )
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        "מתוך: " +
                                          _vm._s(
                                            _vm.question.firstAppearance
                                              .lessonName
                                          ) +
                                          " בקורס: " +
                                          _vm._s(
                                            _vm.question.firstAppearance
                                              .courseName
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "question-instances" },
                              _vm._l(_vm.question.lastFiveAppearances, function(
                                instance
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: instance.id,
                                    staticClass: "question-instance"
                                  },
                                  [
                                    _c("a", { attrs: { href: "" } }, [
                                      _vm._v(_vm._s(instance.exerciseName))
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        "מתוך: " +
                                          _vm._s(instance.lessonName) +
                                          " בקורס: " +
                                          _vm._s(instance.courseName)
                                      )
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "question-save-buttons" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "new flex-2",
                                  attrs: { type: "is-info" },
                                  on: { click: _vm.saveQuestion }
                                },
                                [_vm._v("שמירת שינויים")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ]
              )
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }