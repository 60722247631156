<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">שיוך וידאו ליחידת לימוד</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">

                    <FieldInlineText label="שם" v-model="model.name" />

                    <FieldInlineSelect label="יחידת לימוד" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getLessons" v-model="model.lessonId" />

                    <FieldInlineSelect v-if="!edit" :alert-not-exist="true" :hide-hover="true" label="בחירת וידאו"
                        :clearable="true" :filterable="true" placeholder="הקלד שם וידאו לחיפוש" :options="[]"
                        :fetch-options="onSearchVehicles" @onChange="onChange" v-model="model.videoId" />

                    <FieldInlineSelect label="מקבץ חקיקה" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getlawCollections" v-model="model.lawCollectionId" />

                    <FieldInlineSelect label="תקצירי פסיקה" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getVerdictCollections" v-model="model.verdictCollectionId" />

                    <FieldInlineSelect label="קטגוריה" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getCategories" v-model="model.categoryId" />

                    <FieldInlineText label="נושא" v-model="model.videoCategory" />

                    <div v-if="edit" class="checkbox-container">
                        <b-switch class="ltr" @input="onChange" v-model="model.status">{{
                            model.status ? "פעיל" : "לא פעיל"
                            }}</b-switch>

                        <b-switch class="ltr" @input="onChange" v-model="model.isInteractiveVideo">{{
                            model.isInteractiveVideo ? "אינטראקטיבי" : "לא אינטראקטיבי"
                            }}</b-switch>
                    </div>

                    <b-field v-if="!edit" label="חומרי עזר">
                        <!-- <ckeditor :editor="editor" placeholder="'חומרי עזר'" v-model="model.supportingMaterials"
                            :config="editorConfig"></ckeditor> -->
                        <NewActivityEditor @onEditorChange="onEditorChange" :ckEditorContent="model.supportingMaterials" :content="model.supportingMaterials" ref="editor" />
                    </b-field>

                    <!-- <div style="display: flex; flex-direction: column; margin-top: 15px;">
                        <div>
                            <b-checkbox style="gap: 10px" v-model="model.status" label="" type="is-link is-light"
                                class="checkbox">
                                {{ model.status ? ' פעיל' : 'לא פעיל' }}
                            </b-checkbox>
                        </div>

                        <div>
                            <b-checkbox style="gap: 10px" v-model="model.isInteractiveVideo" label=""
                                type="is-link is-light" class="checkbox">
                                {{ model.isInteractiveVideo ? 'אינטראקטיבי' : 'לא אינטראקטיבי' }}
                            </b-checkbox>
                        </div>
                    </div> -->

                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import AdminService from "@/services/AdminService";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastProgrammatic as Toast } from "buefy";
import CoursesService from "../services/CoursesService";
import LessonService from "../services/LessonService";
import NewActivityEditor from "./NewActivityEditor.vue";

const {
    ClassicEditor,
    Alignment,
    Autoformat,
    Base64UploadAdapter,
    AutoImage,
    AutoLink,
    Autosave,
    BalloonToolbar,
    BlockQuote,
    Bold,
    Bookmark,
    CloudServices,
    Code,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    //
    MediaEmbed,
    Paragraph,
    PasteFromMarkdownExperimental,
    PasteFromOffice,
    RemoveFormat,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Style,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    TodoList,
    Underline
} = CKEDITOR;

export default {
    name: "DialogAdd",
    props: ["onSuccess", "store", "lessonId", "video", "edit"],
    components: {
        FieldInlineSelect,
        FieldInlineText,
        NewActivityEditor,
    },
    created() {
        if (this.lessonId) this.model.lessonId = this.lessonId;
        if (this.edit) this.model = this.video;
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            loading: false,
            model: {
                id: null,
                name: null,
                lessonId: null,
                videoId: null,
                lawCollectionId: null,
                verdictCollectionId: null,
                categoryId: null,
                videoCategory: '',
                supportingMaterials: '',
                sortOrder: null,
                status: null,
                isInteractiveVideo: null,
            },
            editor: ClassicEditor,
            editorConfig: {
                language: 'he',
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'fontSize',
                        'fontFamily',
                        'fontColor',
                        'fontBackgroundColor',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        '|',
                        'link',
                        'insertTable',
                        'highlight',
                        '|',
                        'alignment',
                        '|',
                        'bulletedList',
                        'numberedList',
                        'todoList',
                        'outdent',
                        'indent'
                    ],
                    shouldNotGroupWhenFull: false
                },
                plugins: [
                    Alignment,
                    Autoformat,
                    Base64UploadAdapter,
                    AutoImage,
                    AutoLink,
                    Autosave,
                    BalloonToolbar,
                    BlockQuote,
                    Bold,
                    Bookmark,
                    CloudServices,
                    Code,
                    Essentials,
                    FontBackgroundColor,
                    FontColor,
                    FontFamily,
                    FontSize,
                    GeneralHtmlSupport,
                    Heading,
                    Highlight,
                    HorizontalLine,
                    HtmlComment,
                    HtmlEmbed,
                    ImageBlock,
                    ImageCaption,
                    ImageInline,
                    ImageInsertViaUrl,
                    ImageResize,
                    ImageStyle,
                    ImageTextAlternative,
                    ImageToolbar,
                    ImageUpload,
                    Indent,
                    IndentBlock,
                    Italic,
                    Link,
                    LinkImage,
                    List,
                    ListProperties,
                    //
                    MediaEmbed,
                    Paragraph,
                    PasteFromMarkdownExperimental,
                    PasteFromOffice,
                    RemoveFormat,
                    SpecialCharacters,
                    SpecialCharactersArrows,
                    SpecialCharactersCurrency,
                    SpecialCharactersEssentials,
                    SpecialCharactersLatin,
                    SpecialCharactersMathematical,
                    SpecialCharactersText,
                    Strikethrough,
                    Style,
                    Subscript,
                    Superscript,
                    Table,
                    TableCaption,
                    TableCellProperties,
                    TableColumnResize,
                    TableProperties,
                    TableToolbar,
                    TextTransformation,
                    TodoList,
                    Underline
                ],
                balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
                fontFamily: {
                    supportAllValues: true
                },
                fontSize: {
                    options: [10, 12, 14, 'default', 18, 20, 22],
                    supportAllValues: true
                },
                heading: {
                    options: [
                        {
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph'
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1'
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2'
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3'
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4'
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5'
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6'
                        }
                    ]
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /^.*$/,
                            styles: true,
                            attributes: true,
                            classes: true
                        }
                    ]
                },
                image: {
                    toolbar: [
                        'toggleImageCaption',
                        'imageTextAlternative',
                        '|',
                        'imageStyle:inline',
                        'imageStyle:wrapText',
                        'imageStyle:breakText',
                        '|',
                        'resizeImage'
                    ]
                },
                licenseKey: 'eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NjcxMzkxOTksImp0aSI6IjAzMzQyMjlmLTMxYmUtNGU2MC1hNjgyLTIzOGU5YWE1M2MxMCIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiXSwiZmVhdHVyZXMiOlsiRFJVUCJdLCJ2YyI6IjY5N2VlNDZmIn0.j0Qq4l_jAjCruUz_bUcn9aWq2HwWwi-ffnXv4z6aYIvjG5tapML2zQCf2ArFDo5s9qFnawNwpkE9_rXOa7gjUA',
                link: {
                    addTargetToExternalLinks: true,
                    defaultProtocol: 'https://',
                    decorators: {
                        toggleDownloadable: {
                            mode: 'manual',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file'
                            }
                        }
                    }
                },
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true
                    }
                },
                menuBar: {
                    isVisible: true
                },
                placeholder: '',
                style: {
                    definitions: [
                        {
                            name: 'Article category',
                            element: 'h3',
                            classes: ['category']
                        },
                        {
                            name: 'Title',
                            element: 'h2',
                            classes: ['document-title']
                        },
                        {
                            name: 'Subtitle',
                            element: 'h3',
                            classes: ['document-subtitle']
                        },
                        {
                            name: 'Info box',
                            element: 'p',
                            classes: ['info-box']
                        },
                        {
                            name: 'Side quote',
                            element: 'blockquote',
                            classes: ['side-quote']
                        },
                        {
                            name: 'Marker',
                            element: 'span',
                            classes: ['marker']
                        },
                        {
                            name: 'Spoiler',
                            element: 'span',
                            classes: ['spoiler']
                        },
                        {
                            name: 'Code (dark)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-dark']
                        },
                        {
                            name: 'Code (bright)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-bright']
                        }
                    ]
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
                }
            },
        };
    },
    computed: {
    },
    methods: {
        save() {
            this.submitted = true;
            if (this.model.name) {
                // this.model.supportingMaterials = this.$refs.editor.getHTML();
                if (this.lessonId) this.model.lessonId = +this.model.lessonId.Value;
                this.model.ExerciseCategoryId = this.model.categoryId;
                this.isLoading = true;
                const request = this.edit ? LessonService.updateVideo(this.store.state.auth.course.id, this.model.id, {
                    ...this.model,
                }) : LessonService.addLessonVideo(this.store.state.auth.course.id, {
                    ...this.model
                })
                request
                    .then(() => {
                        // this.reports.map((t, idx) => (
                        //     t.activities.unshift(r[idx].data)
                        // ));
                        this.$emit("close");
                        if (this.onSuccess) this.onSuccess();
                        window.location.reload();
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onEditorChange(value) {
            this.model.supportingMaterials = value;
        },
        onChange() { },
        getLessons() {
            return () => CoursesService.getCourse(this.store.state.auth.course.id,
                {
                    PageSize: 999,
                    IsAscending: true,
                    PageNumber: 1,
                    ResultType: 3
                },
                false).then((r) => r.data.items.map((u) => ({
                    Value: u.id,
                    Text: `${u.id} - ${u.name}`
                }))
                );
        },
        getCategories() {
            return () =>
                CoursesService.getCategories().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        getlawCollections() {
            return () =>
                CoursesService.getLawCollections().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        getVerdictCollections() {
            return () =>
                CoursesService.getVerdictCollections().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        onSearchVehicles({ search, loading, setData, setValue }) {
            if (search.length > 3) {
                loading(true);
                return AdminService.getVideosInBank({
                    Terms: search
                })
                    .then((r) => {
                        if (!r.data.items.length) {
                            setValue({ Value: -1, Text: search });
                        } else {
                            setData(r.data.items.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name} | ${i.videoTypeName} - ${i.exerciseCategoryName}` })));
                        }
                    })
                    .finally(() => {
                        loading(false);
                    });
            }

            return null;
        },
    },
};
</script>

<style lang="scss"></style>
